$(function() {

  //slideNav animation
  let slideNavOpened = false
  let loginSlideDownOpened = false
  
  $('.slide-nav-click-outside').click(() => {
    if(loginSlideDownOpened){
      loginSlideDownToggle()
    }
    else if(slideNavOpened){
      slideNavToggle()
    }
  })

  $('.nav--hamburger, .nav--exit').click(() => {
    slideNavToggle()
  })

  $('.toggle-loginSlideDown').click(() => {
    loginSlideDownToggle()
  })

  $('.sign-in-here').click(() => {
    loginSlideDownToggle()
  })

  const addBlur = () => {
    $('#page, footer').addClass('blur')
    $('.nav--link a').animate({ opacity: '0.3' }, 0, 'ease')
    $('.slide-nav-click-outside').addClass('nav-open')
  }

  const removeBlur = () => {
    $('#page, footer').removeClass('blur')
    $('.nav--link a').animate({ opacity: '1' }, 0, 'ease')
    $('.slide-nav-click-outside').removeClass('nav-open')
  }

  const slideNavToggle = (unblur) => {
    const slideNav = $('.slide-nav')
    var unblur = unblur || 'true'

    // close login slide down if it is open, else it will blur
    if (loginSlideDownOpened) {
      loginSlideDownToggle('false')
    } else {
      addBlur()
    }

    // if slide nav is open, slide off screen, else slide on screen
    if (slideNavOpened) {
      slideNav.css('transform', 'translateX(-100%)')
      $('.nav--exit').addClass('nav--hamburger').removeClass('nav--exit')
      if (unblur === 'true') {
        removeBlur()
      }
    } else {
      slideNav.css('transform', 'translateX(0)')
      $('.nav--hamburger').addClass('nav--exit').removeClass('nav--hamburger')
    }
    
    slideNavOpened = !slideNavOpened
  }


  const loginSlideDownToggle = (unblur) => {
    var unblur = unblur || 'true'

    // if the slidenav is open close it, else blur the background
    if (slideNavOpened) {
      slideNavToggle('false')
    } else {
      addBlur()
    }

    // toggle the login slide down
    if (loginSlideDownOpened) {
      $('.nav--login-form').addClass('nav--login-form__hidden')
      $('.nav--login').removeClass('nav--login__active')
      if (unblur === 'true') {
        removeBlur()
      }
    } else {
      $('.nav--login-form').removeClass('nav--login-form__hidden')
      $('.nav--login').addClass('nav--login__active')
    }

    loginSlideDownOpened = !loginSlideDownOpened
  }

  $('.nav--search-wrapper').click(function() {
    $('.nav--wrapper').addClass('search-active')
    $('.nav--search-bar').focus()
  })

  $('.nav--search-exit').click(function() {
    $('.nav--wrapper').removeClass('search-active')
  })

  // if any link is clicked in the navigation slider menu, the slider should close
  $('.slide-nav a').click(function () {
    slideNavToggle(false)
  })

})
