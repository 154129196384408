/* global PhotoEditorSDK, Image */
function passToPhotoEditor(imgSrc) {
  
      var myImage = new Image();
      myImage.src = imgSrc;//get previous source of image
      myImage.addEventListener('load', function () {
        document.querySelector(".pop-up-editor").classList.add("__show");
        var editor
        'use strict';

        function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

        function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

        function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

        var ReactUI = PhotoEditorSDK.UI.ReactUI;
        var _PhotoEditorSDK = PhotoEditorSDK;
        var Promise = _PhotoEditorSDK.Promise;

        

        editor = new ReactUI({
          container: document.getElementById('photo-editor-container'),
          logLevel: 'info',
          apiKey: 'PpLepwgvKs4c9O,no7t6w',
          enableWebcam: false,
          editor: {
            image: myImage,
            preferredRenderer: 'webgl',
            export: {
              download: false,
              type: PhotoEditorSDK.RenderType.DATAURL
            },
            tools: ['crop', 'filter', 'brightness', 'saturation', 'contrast', 'clarity', 'exposure', 'shadows', 'highlights', 'text', 'sticker', 'brush', 'radial-focus', 'linear-focus', 'border', 'selective-blur']
          },
          /*photoRoll: {
            provider: UnsplashProvider
          },*/
          assets: {
            baseUrl: '/assets/photo-editor/assets',
            resolver: function (path) {
              return path
            }
          },
          responsive: true,
          language: 'en'
        })
        editor.on('export', (dataURL) => {
          document.querySelector(".pop-up-editor").classList.remove("__show");
          $.ajax({
            type: "POST",
            //url: "/my-upload-handler.php", this is where we pass image data
            data: {
              image: dataURL
            }
          }).done(function() {
            //editor.
            console.log('saved');
          })
      })
    })
}

window.onload = function () {
  

  /**
   * Load initial image, initialize UI
   */


  /**
   * Handle links
   *//*
  var webglLink = document.body.querySelector('#webgl')
  var canvasLink = document.body.querySelector('#canvas')
  webglLink.addEventListener('click', function (e) {
    e.preventDefault()
    editor.dispose()
    canvasLink.classList.remove('active')
    webglLink.classList.add('active')
    run('webgl')
  })

  canvasLink.addEventListener('click', function (e) {
    e.preventDefault()
    editor.dispose()
    webglLink.classList.remove('active')
    canvasLink.classList.add('active')
    run('canvas')
  })*/
}