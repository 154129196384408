jQuery(function($) {
  $('.account-change-profile-photo').click(function(){
    passToPhotoEditor('/assets/img/charles.jpg');
  })
  $('.account-management-modal--btn__exit, .account-management-modal--btn__save').click(function() {
    $('#accountManagementModal').modal('hide')
  })

  $('.account-management-modal--btn__upload').click(function() {
    $('.account-management-modal--profile-img').css("background-image", "url(http://placekitten.com/800/800)")
    $(this).hide()
    $('.account-management-modal--btn__new').show().css("display", "block")
    $('.account-management-modal--btn__save').show()
  })

  $('.account-management-modal--btn__new').click(function() {
    $('.account-management-modal--profile-img').css("background-image", "url(http://placekitten.com/700/700)")
  })
})
