jQuery(function($) {
  $('.need-card--more-photos').click(function() {
    $('#need-image-modal').modal('show')
    setTimeout(function() {
      window.slider = $("#need-slider").not('.slick-initialized').slick({
        centerMode: true,
        centerPadding: '200px',
        slidesToShow: 3,
        variableWidth: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              centerMode: true,
              centerPadding: '200px',
              slidesToShow: 3,
              variableWidth: true,
              arrows: false,
            }
          },
          {
            breakpoint: 768,
            settings: {
              centerMode: true,
              centerPadding: '200px',
              slidesToShow: 3,
              variableWidth: true,
              arrows: false,
            }
          },
          {
            breakpoint: 480,
            settings: {
              centerMode: true,
              centerPadding: '200px',
              slidesToShow: 3,
              variableWidth: true,
              arrows: false,
            }
          }
        ]
      })
      .on('afterChange', function() {
        $('.need-slider--current-slide-num').text($("#need-slider").slick('slickCurrentSlide') + 1)
      })

      const slideCount = $('.slick-slide').not('.slick-cloned').length

      $('.need-slider--total-slide-num')
        .text(slideCount)

      $('.need-modal-prev').click(function() {
        $('#need-slider').slick('slickPrev')
      })

      $('.need-modal-next').click(function() {
        $('#need-slider').slick('slickNext')
      })

    }, 50)
  })

  $('.need-modal-close').click(function() {
    $('#need-image-modal').modal('hide')
  })

  // home encircle icons slider

  $(".home-roles--container").slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
        infinite: true,
        dots: true
      }
    }]
  })


  // Landing Page icons slider

  $(".landing-roles--container").slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
        infinite: true,
        dots: true
      }
    }]
  })

  // current needs sliders

  $('.search-needs-urgent--cards').slick({
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          autoplay: true,
          autoplaySpeed: 3000,
          arrows: false,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          autoplay: true,
          autoplaySpeed: 3000,
          arrows: false,
        }
      },
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          arrows: true,
        }
      }
    ]
  })

})
