jQuery(function($) {
  // checkout modal steps
  // initial, thank-you, sign-in, confirm, choose-card, add-card
  // things chagne if user is signed in or not

  function showCheckoutModalStepContent(stepName, signedIn) {
    const $checkoutModal = $('.checkout-modal')
    $checkoutModal.attr('data-current-step', stepName)
    if (signedIn) $('.checkout-modal').attr('data-signed-in', signedIn)

    let isSignedIn = $('.checkout-modal').attr('data-signed-in')
    let conditionalElements = $checkoutModal.find('[data-step], [data-signed-in]')

    conditionalElements.each((index, elem) => {
      var $elem = $(elem)
      var elemStep = $elem.attr('data-step')
      var elemSignedIn = $elem.attr('data-signed-in')

      if (elemStep && elemSignedIn) {
        if (elemStep === stepName && elemSignedIn === isSignedIn) $elem.show()
        else $elem.hide()
      } else if (elemStep) {
        if (elemStep === stepName) $elem.show()
        else $elem.hide()
      } else if (elemSignedIn) {
        if (elemSignedIn === isSignedIn) $elem.show()
        else $elem.hide()
      }
    })
  }

  showCheckoutModalStepContent('initial')

  $('.checkout-modal--change-donation-amount').click(function() {
    showCheckoutModalStepContent('initial')
  })

  $('.checkout-modal--card-change').click(function() {
    showCheckoutModalStepContent('choose-card')
  })

  $('.checkout-modal--donate-btn').click(function() {
    showCheckoutModalStepContent('confirm')
  })

  $('.checkout-modal--submit-donation').click(function() {
    showCheckoutModalStepContent('thank-you')
  })

  $('.checkout-modal--to-sign-in').click(function() {
    showCheckoutModalStepContent('sign-in')
  })

  $('.checkout-modal--add-card').click(function() {
    showCheckoutModalStepContent('add-card')
  })

  $('.checkout-modal--sign-in').click(function(e) {
    e.preventDefault()
    showCheckoutModalStepContent('initial', 'true')
  })

  $('.checkout-modal--sign-up').click(function(e) {
    e.preventDefault()
    showCheckoutModalStepContent('sign-up')
  })

  $('.checkout-modal--continue-donation').click(function() {
    var cardNumber = $('.checkout-modal--card__active .checkout-modal--card-number span').text()
    $('.checkout-modal--card-section .checkout-modal--card .checkout-modal--card-number span').text(cardNumber)
    showCheckoutModalStepContent('initial', 'true')
  })

  $('[data-step="choose-card"] .checkout-modal--card').click(function() {
    $('[data-step="choose-card"] .checkout-modal--card').removeClass('checkout-modal--card__active')
    $(this).addClass('checkout-modal--card__active')
  })

  $('.checkout-modal--exit').click(function() {
    $('#checkoutModal').modal('hide')
    setTimeout(function() {
      showCheckoutModalStepContent('initial')
    }, 300)
  })

  $('.checkout-modal--price-option').click(function() {
    $('.checkout-modal--price-option').removeClass('checkout-modal--price-option__active')
    $(this).addClass('checkout-modal--price-option__active')
  })

  $('#customPriceLink').click(function() {
    $('#customPriceLinkContainer').addClass('hidden')
    $('.checkout-modal--price-custom-container').show()
  })
})
