jQuery(function($) {
  // nav scroll to links
  $('.nav--link a').click(function(e) {
    if (e.target.href.includes('howItWorks')) {
      if (window.location.href.includes('home')) {
        e.preventDefault()
        scrollToId('howItWorks')
      }
    }
  })

  if (window.location.href.includes('/home.html#howItWorks')) {
    scrollToId('howItWorks')
  }


  function scrollToId(id) {
    var windowWidth = window.innerWidth
    var offsetPadding = 40
    if (windowWidth > 1200 && windowWidth < 1441) {
      offsetPadding = 36
    } else if (windowWidth < 1201) {
      offsetPadding = 32
    }

    $("html, body").animate({
      scrollTop: $('#' + id).offset().top - offsetPadding
    }, 1000)
  }


  // print table functionality
  function printData() {
    var detailsToPrint = document.getElementById("printDetails")
    var tableToPrint = document.getElementById("printTable")
    var newWin = window.open( "" )
    newWin.document.write(detailsToPrint.outerHTML)
    newWin.document.write(tableToPrint.outerHTML)
    newWin.print()
    newWin.close()
  }

  $('.print-table').click(function() {
    printData()
  })

  $('.read-more-btn').click(function() {
    var $this = $(this)
    var isOpen = $this.attr('data-toggled')
    var $target = $('#' + $this.attr('data-target'))

    if (isOpen === 'false') {
      $target.removeClass($this.attr('data-toggle-class'))
      $this.text('show less').attr('data-toggled', 'true')
    } else {
      $target.addClass($this.attr('data-toggle-class'))
      $this.text('read more').attr('data-toggled', 'false')
    }
  })
})
