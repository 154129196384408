jQuery(function($) {

  var givingPieElem

  if (document.getElementById('givingPieChart')) {
    givingPieElem = document.getElementById('givingPieChart').getContext('2d')
  }

  if (givingPieElem) {
    givingPieElem.canvas.width = 50
    givingPieElem.canvas.height = 50

    var givingData = {
      labels: ['Hunger', 'Education', 'Health Services'],
      datasets: [
        {
          data: [50, 25, 25],
          backgroundColor: ['#21B78C', '#003759', '#097189'],
          borderWidth: 0
        }
      ]
    }

    var givingOptions = {
      legend: {
        position: 'left'
      },
      responsive: true,
      animation: {
        onComplete: function () {
          var ctx = this.chart.ctx
          ctx.font = Chart.helpers.fontString(26, 'normal', 'brandon-grotesque')
          ctx.textAlign = 'center'
          ctx.textBaseline = 'bottom'

          this.data.datasets.forEach(function (dataset) {

            for (var i = 0; i < dataset.data.length; i++) {
              var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                  total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                  mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                  start_angle = model.startAngle,
                  end_angle = model.endAngle,
                  mid_angle = start_angle + (end_angle - start_angle)/2

              var x = mid_radius * Math.cos(mid_angle)
              var y = mid_radius * Math.sin(mid_angle)

              ctx.fillStyle = '#fff'

              var percent = String(Math.round(dataset.data[i]/total*100)) + "%"
              // Display percent in another line, line break doesn't work for fillText
              ctx.fillText(percent, model.x + x, model.y + y + 15)
            }
          })
        }
      }
    }


    var givingPieChart = new Chart(givingPieElem, {
      type: 'pie',
      data: givingData,
      options: givingOptions
    })

  }
})
