jQuery(function($) {

  $('#filter-needs').click(function () {
    $('#filter-top').toggle()
  })

  $('#dropdown-selection').click(function () {
    $('#category-dropdown').toggle()
    return false
  })

  $(window).on('resize', function(e) {
    clearTimeout(resizeTimer)
    var resizeTimer = setTimeout(function() {
      if (window.innerWidth > 767) {
        $('#filter-top').css('display', 'flex')
      }
    }, 200)
  })

  $('.search-needs-all--dropdown-item .search-needs-all--dropdown-link').click(function(e) {
    var newText = $(this).text()
    var your_div = document.getElementById('dropdown-selection').childNodes[0].nodeValue = newText
  })
})
