$(() => {

  $('.need-preferences--matter-most').select2({
    placeholder: 'Which needs matter most to you?',
    minimumResultsForSearch: Infinity
  })

  $('.sign-up--location').change(function(e) {
    var $field = $(this)
    var $fields = $field.closest('.sign-up--location-form').find('[data-usa]')
    console.log(this);

    if (e.target.value === 'united state of america') {

      $fields.each(function(index, field) {
        var $currentField = $(field)
        if ($currentField.attr('data-usa') === 'true') $currentField.removeClass('hidden')
        else $currentField.addClass('hidden')
      })

    } else {

      $fields.each(function(index, field) {
        var $currentField = $(field)
        if ($currentField.attr('data-usa') === 'true') $currentField.addClass('hidden')
        else $currentField.removeClass('hidden')
      })

    }
  })

  var NTHS = {
    '2': 'Second',
    '3': 'Third',
    '4': 'Fourth',
    '5': 'Fifth',
    '6': 'Sixth',
    '7': 'Seventh',
    '8': 'Eighth',
    '9': 'Ninth',
    '10': 'Tenth',
  }

  $('.sign-up--add-location').click(function() {
    $($('.sign-up--location-form')[0]).clone(true, true).appendTo('.sign-up--location-forms')
    var $forms = $('.sign-up--location-form')
    var currentNth = function() {
      if ($forms.length > 10) return 'Another'
      else return NTHS[$forms.length]
    }

    $($forms[$forms.length - 1]).find('fieldset > label').text(currentNth() + " Location")
  })


  $('.sign-up--delete-location').click(function() {
    $(this).closest('.sign-up--location-form').remove()
    $('.sign-up--location-forms')
      .children()
      .each(function(index, form) {
        if (index > 0) {
          var $title = $(form).find('label')
          var titleWords = $title.text().split(' ')
          titleWords[0] = NTHS[index + 1]
          $title.text(titleWords.join(' '))
        }
      })
  })

  $('select.form-control')
    .focus(function() {
        $(this).parent().addClass('select--wrapper__focus');
    })
    .blur(function() {
        $(this).parent().removeClass('select--wrapper__focus');
    });
})
