jQuery(function($) {

  $('#addCreditButton').click(function () {
    $('#addCreditButton').addClass('hidden')
    $('#addCreditForm').removeClass('hidden')
  })

  $('#addCreditCancel').click(function () {
    $('#addCreditButton').removeClass('hidden')
    $('#addCreditForm').addClass('hidden')
  })

  $('#addCreditNew').click(function () {
    $('#addCreditButton').removeClass('hidden')
    $('#addCreditForm').addClass('hidden')
    $('.newCard').removeClass('hidden')
  })

})
