jQuery(function($) {

  $('.donation-card--share').click(function(e) {
    e.preventDefault()
    $(this).children().toggleClass('hidden')
  })

  var $donationCards = $('.donation-cards')
  var isCardsSliderActive = true

  function initHomeCardsSlider() {
    $donationCards.slick({
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
      ]
    })
    console.log("hey")
  }

  initHomeCardsSlider();


  $(window).on('resize', function(e) {
    clearTimeout(resizeTimer)
    var resizeTimer = setTimeout(function() {
      if (!isCardsSliderActive && $('.slick-initialized')) {
        initHomeCardsSlider();
        isCardSliderActive = true;
      }
      /*
      if (window.innerWidth > 992) {
        $donationCards.slick('unslick')
        isCardsSliderActive = false
      } else if (!isCardsSliderActive && $('.slick-initialized')) {
        initHomeCardsSlider()
        isCardsSliderActive = true
      }
      */
    }, 200)
  })
})


//&--share
//&--dropdown
